import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

class PaymentTerms extends Component {
  state = {};

  componentDidMount() {
    this.context.setPageTitle('Terms Of Service');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5 className="main-heading">REFUND AND CANCELLATION</h5>
              <p>
                We understand that circumstances can change, and we strive to provide a fair and transparent refund process. Our policy is divided into two parts: Cancellations initiated by the patient and Refunds for tests that cannot be completed by AmarLab.
              </p>
              <ul className="refundul">
                <li>
                  <strong>Patient-Initiated Cancellations:</strong><br />
                  If a patient cancels a pathology test prior to the call of the patient’s request and within <strong>60 minutes</strong> of initiating the request, a <strong>5% processing fee</strong> will be deducted from the original payment. The remaining amount will be refunded to the patient.
                </li>
                <li>
                  <strong>Refunds for Uncompleted Tests:</strong><br />
                  In the unlikely event that AmarLab is unable to perform a scheduled pathology test due to unforeseen circumstances such as equipment malfunction, technical issues, or other reasons beyond our control, a full refund of the test fee will be issued to the patient. We will make every effort to notify the patient as soon as possible in such situations.
                </li>
                <li>
                  <strong>Refund Processing:</strong><br />
                  All eligible refunds, whether due to patient cancellation (within the specified timeframe) or uncompleted tests by AmarLab, will be processed within <strong>10 working days</strong>. The refund will be issued through the original payment method used for the transaction.
                </li>
                <li>
                  For any questions regarding cancellations or refunds, please contact us at:{' '}
                  <a href="mailto:info@amarlab.com" className="text-primary">
                    info@amarlab.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      </>
    );
  }
}

export default PaymentTerms;
PaymentTerms.contextType = AuthContext;
